<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
			:slot_table_list="['operation','is_leader','is_attendance','user_status','department','is_sync','role_name']" ref="list"
			:submit_preprocessing="submit_preprocessing">
			<template slot="left_btn">
				<a-button  type="primary" class="float_left" v-auth="'change'" style="margin-left:15px" @click="facevisible=true"
					>新增拜访打卡规则</a-button>
			</template>

			<template slot="operation" slot-scope="data">
				<a @click="edit(data.record,data.index)">编辑</a>
				
			</template>
		</TableList>
		<EditPop width='50%' :form_data="face_form_data" :visible.sync="facevisible">
			<template slot="rule_user">
			    <TransferTree
			        @change="TransferTreeChange"
			        :treeData="treeData"
			        :value="treeDataValue"
			    />
			    <a-input hidden="true" v-decorator="['rule_user']"></a-input>
			</template>
		</EditPop>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TransferTree from "@/components/TransferTreeTwo";
	
	import {
		user_index,
		updateDepartment,userPasswordInit
	} from "@/api/user";
	import TableList from "@/components/TableList";
	import deactivated from '@/methods/deactivated'
	const columns = [{
			title: "ID",
			dataIndex: "id",
		},
		{
			title: "规则姓名",
			dataIndex: "username",
			
		},
		{
			title: "使用人数",
			dataIndex: "username2",
			
		},
		{
			title: "规则姓名",
			dataIndex: "username3",
			
		},
		{
			title: "状态",
			dataIndex: "username4",
			
		},
		{
			title: "创建时间",
			dataIndex: "username5",
			
		},
		{
			title: "更新时间",
			dataIndex: "username5",
			
		},
		
		{
			title: "操作",
			dataIndex: "operation",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	

	export default {
		name: "IndexUser",
		components: {
			EditPop,
			Form,
			TableList,TransferTree
		},
		data() {
			return {
				treeData: [],
				treeDataValue: [],
				facevisible:false,
				get_table_list: user_index,
				
				
				submit_preprocessing: {
					array_to_string: ['department_id','role_id']
				},
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				form_data_seo: {
					list: [{
							type: "tree-select",
							name: "department_id",
							title: "部门",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "tree-select",
							name: "role_id",
							title: "岗位职务",
							mode: "default",
							options: {},
							treeData: [],
							multiple: true
						},
						{
							type: "text",
							name: "keyword",
							title: "员工",
							placeholder: '姓名/ID/手机号',
							options: {

							}
						},
						{
							type: "select",
							name: "status",
							title: "在职状态",
							mode: "default",
							options: {},
							list: this.$config.user_status_list
						}
					],
					...this.$config.form_data_seo
				},
				form_data: {},
				face_form_data:{
					list: [
						{
							type: "text",
							name: "keyword",
							title: "规则名称",
							placeholder: '姓名/ID/手机号',
							options: {
						
							}
						},
						
						
							{
									type: "radio",
									name: "status",
									title: "状态",
									options: {
										rules: [{
											required: true,
											message: "请选择状态"
										}],
										initialValue: 1
									},
									list: [{
											key: 1,
											value: "是"
										},
										{
											key: -1,
											value: "否"
										}
									]
								},
								{
								    type: "slot",
								    name: "rule_user",
								    title: "规则适用人员",
								    options: {},
								},
						]
				},
			};
		},

		deactivated() {
			// this.$keep_route.remove(this.$options.name);
		},
		async created() {
			// 为页面添加缓存
			this.get_list();
			this.$method.get_department().then(res => {
				this.form_data_seo.list.forEach(item => {
					if (item.name == "department_id") {
						item.treeData = res;
					}
				});
				
			});
				this.treeData = this.$method.get_user_department_list(res.data.list,'sysUserTree') 
			
		},
		methods: {
			TransferTreeChange(value) {
			    this.$refs.form.setFieldsValue({
			        rule_user: value.join(',')
			    });
			},
			async handleTableChange(pagination) {
				this.page = pagination.current;
				await this.get_list();
			},
			async get_list(data = {}) {
				try {
					await user_index({
						_this: this,
						data
					}).then(res => {
						let list = res.res.data.list.data;
						this.list = list.map(item => {
							item.department_name = item.department_join.department_join;
							return item;
						});
					});
				} catch (e) {
				}
			},
		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");
</style>
